import wrapWithProvider from './src/state/wrap-with-provider';
import SmoothScroll from 'smooth-scroll';

export const wrapRootElement = wrapWithProvider;

export const onRouteUpdate = ({ location: { hash } }) => {
    /*if (hash) {
        window.setTimeout(() => {
            let scroll = new SmoothScroll();
            let anchor = document.querySelector(hash);
            if (anchor) {
                scroll.animateScroll(anchor);
            }
        }
        , 100);
    }*/
};
